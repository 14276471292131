import React from "react"
import { Container } from "reactstrap"

import Layout from "../components/layout"
import MastHead from "../components/masthead"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <MastHead title="Sameer Segal" subtitle="Technology for those who need it the most"/>
    <Container>
      <p>Sorry, this page does not exist</p>
    </Container>
  </Layout>
)

export default NotFoundPage
